#alcool-lnd .swiper {

	overflow: initial !important;

	&-controls {
		display: flex;
		margin-top: 24px;
	}

	&-pagination {
		position: initial;
		width: auto;
	}

	&-button {

		&-next, 
		&-prev {

			position: relative;
			top: initial;
			right: initial;
			left: initial;
			width: 48px;
			height: 48px;
			margin: 0;
			transition: all .4s ease-in-out;

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url('../svg/arrow-slider.svg') no-repeat center center;
				background-sizde: contain;
			}
	
		}
		&-prev {
			transform: scaleX(-1);
		}

		&-disabled {
			opacity: .15;
		}

	}

	&-arrows,
	&-controls:before {
		width: 112px;
		min-width: 112px;
	}

	&-arrows {
		display: flex;
		justify-content: space-between;
	}

	&-pagination {

		display: flex;
		gap: 12px;

		&-bullet {

			display: flex;
			align-items: center;
			width: 65px;
			height: 48px;
			margin: 0;
			border-radius: 0;
			opacity: 1;
			background: none;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 10px;
				border-radius: 5px;
				background: #D9D9D9;
				transition: all .4s ease-in-out;
			}

			&-active:before {
				background-color: #162639;
			}
		
		}

	}

}

@media( min-width: 769px ){
	
	#alcool-lnd .swiper {

		&-controls {

			justify-content: space-between;
			
			&:before {
				content: '';
				display: block;
			}
			
		}

	}

}

@media( max-width: 1080px ){
	
	#alcool-lnd .swiper-pagination-bullet {

		width: 25px;

		&:before {
			height: 5px;
			border-radius: 2.5px;
		}

	}

}
@media( max-width: 768px ){
	
	#alcool-lnd .swiper {

		&-controls {
			justify-content: center;
		}

	}

}

@media( max-width: 480px ){
	
	#alcool-lnd .swiper {

		&-controls {
			margin-top: 0;
		}

		&-pagination {

			gap: 8px;

			&-bullet {

				width: 25px;

				&:before {
					height: 5px;
					border-radius: 2.5px;
				}

			}

		}

	}

}