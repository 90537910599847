
@keyframes jumpInfinite {
  	0% {
    	transform: translateY(0);
  	}
  	50% {
    	transform: translateY(12px);
  	}
  	100% {
    	transform: translateY(0);
  	}
}