@font-face{
    font-family: "Halyard Display";
    src: url("../fonts/Halyard-Display.eot");
    src: url("../fonts/Halyard-Display-iefix.eot")format("embedded-opentype"),
        url("../fonts/Halyard-Display.woff")format("woff"),
        url("../fonts/Halyard-Display.woff2")format("woff2"),
        url("../fonts/Halyard-Display.ttf")format("truetype"),
        url("../fonts/Halyard-Display.svg")format("svg");
    font-weight:normal;
    font-style:normal;
    font-display:swap;
}