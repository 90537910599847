html,
body {
    scroll-padding: 86px 0 0 0;
}

#alcool-lnd {

    > header,
    &:before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 86px;

    }

    &:before {
        content: '';
        background: rgba(30, 52, 79, 0.8);
        border-bottom: 1px solid rgba(30, 52, 79, 0.9);
        backdrop-filter: blur(7.5px);
        z-index: 98;
        transition: opacity .4s ease-in-out;
    }

    > header {

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;

        > div {

            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1212px;
            padding: 0 24px;

        }

        h1 {

            display: flex;
            align-items: center;
            margin: 0;
            color: #FEFEFE;

            picture {

                display: flex;
                align-items: center;
                gap: 16px;

                img {
                    display: block;
                    width: auto;
                    height: 32px;
                }
            
            }

            strong {
                line-height: 32px;
                font-size: 24px;
                font-weight: 300;
            }
        
        }

        .floater {

            display: flex;

            #menu a {

                position: relative;
                text-decoration: none;

                strong {

                    position: relative;
                    display: table;
                    font-weight: normal;

                    &,
                    &:before {
                        white-space: nowrap;
                        transition: all .4s ease-in-out;
                    }

                    &:before {
                        content:attr(data-title);
                        display: table;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        color: #FEFEFE;
                        font-weight: 600;
                        transform: translate(-50%,-50%);
                        opacity: 0;
                    }
                
                }

                &:hover:not(.active),
                &.active {

                    strong {

                        color: transparent;

                        &:before {
                            opacity: 1;
                        }
                    
                    }

                }

                &.active strong:before {
                    color: #ffe749;
                }

            }
        
        }

    }

    &.scrolled:before {
        opacity: 1;
    }

}

@media( min-width: 1123px ){

    #alcool-lnd > header {

        .floater {

            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            #menu {

                display: flex;
                gap: 14px;
                margin-right: 12px;

                a {

                    display: block;
                    padding: 0 12px;

                    &,
                    &:before {
                        color: #FEFEFE;
                        font-family: 'Inter';
                        line-height: 32px;
                        font-size: 16px;
                    }

                    &.inicio {
                        display: none;
                    }

                }
            
            }

            .networks {
                display: none;
            }

            button.styled {

                span {

                    font-size: 16px;
                    font-weight: 700;
                    padding-bottom: 2px;

                    &:after {
                        transform: translateY(2px);
                    }
                    
                }

                &:hover:not(:disabled) span:after {
                    transform: translate(5px,2px);
                }
            
            }

        }

        .menu-toggle {
            display: none;
        }

    }

}

@media( max-width: 1228px ){
    #alcool-lnd > header .floater #menu {
        gap: 0;
    }
}

@media( max-width: 1122px ){
    
    html,
    body {
        scroll-padding: 72px 0 0 0;
    }
    
    #alcool-lnd {

        &:before,
        > header {
            height: 72px;
        }

        > header {

            transition: background .4s ease-in-out;
            z-index: 9999;

            > div {
                height: 71px;
            }

            h1 {
                img {
                    width: 187px;
                }
            }

            .floater {

                position: fixed;
                top: 72px;
                right: 0;
                flex-direction: column;
                width: 360px;
                height: calc( 100dvh - 72px );
                background: #FFF;
                overflow-x: hidden;
                overflow-y: auto;
                transition: all .5s ease-in-out;
                transform: translateX(100%);

                > div {

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 26px 24px 32px;
                    margin-top: -1px;
                    background: #F9F9FC;
                    border-top: 1px solid #E3E4E8;

                    button.styled {
                        display: none;
                    }

                    nav {

                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        width: 100%;

                        em {
                            flex-basis: 100%;
                            width: 100%;
                            margin-bottom: 8px;
                            text-align: center;
                            color: #1E344F;
                            font-size: 24px;
                            line-height: 34px;
                        }

                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 48px;
                            height: 48px;
                        }
                    
                    }
                
                }

                button.styled.small {

                    span {

                        height: 48px;
                        padding-bottom: 1px;
                        font-size: 18px;
                        font-weight: 700;

                        &:after {
                            transform: translateY(1px);
                        }

                    }

                    &:hover span {

                        background-color: #1E344F;
                        color: #FEFEFE;

                        &:after {
                            filter: brightness(0) invert(1);
                        }
                    
                    }
                
                }

                #menu {

                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    a {

                        display: flex;
                        justify-content: space-between;
                        padding: 32px;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 27px;
                        color: #333333;
                        text-decoration: none;
                        border-bottom: 1px solid #E3E4E8;

                        strong:before,
                        &.active strong:before {
                            color: #162639;
                        }

                        &:after {
                            content: '';
                            display: block;
                            width: 24px;
                            height: 24px;
                            background: url('../svg/arrow-menu.svg') no-repeat center center;
                        }

                    }
                
                }            

            }

            &:before {
                content: '';
                display: block;
                position: fixed;
                top: 72px;
                left: 0;
                width: 100dvw;
                height: calc( 100dvh - 80px );
                background: rgba( 0, 0, 0, 0.5 );
                opacity: 0;
                transform: translateX(-100%);
                transition:
                    opacity .5s ease-in-out,
                    transform 1ms ease .5s
                ;
            }

        }

        &.menu-opened > header {

            background: #1E344F;
        
            .floater {
                transform: translateX(0);
            }
            
            &:before {
                transition: opacity .5s ease-in-out;
                opacity: 1;
                transform: translateX(0);
            }
        
        }

    }
    
}

@media( min-width: 701px ){
    #alcool-lnd:before {
        opacity: 0;
    }
}

@media( max-width: 580px ){
    #alcool-lnd > header h1 strong {
        font-size: 16px;
        line-height: 24px;
    }
}

@media( min-width: 481px ){

    #alcool-lnd > header h1 strong {
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px solid #E6E8EC;
    }

}

@media( max-width: 480px ){

    #alcool-lnd > header {

        > div {
            justify-content: center;
        }

        h1 {

            flex-direction: column;
            gap: 4px;

            picture img {
                height: 24px;
            }
        
        }
        
        .floater {
            width: 100dvw;
        }
        
        .menu-toggle {
            position: absolute;
            top: 50%;
            right: 28px;
            transform: translateY(-50%);
        }

    }

}
