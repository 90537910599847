#alcool-lnd section#video-campanha {

	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
		margin-bottom: 24px;
	}

}
