#alcool-lnd .list-squares {

    margin-top: 48px;

    &:not(.swiper){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
    }

    .list-item {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 40px 24px;
        gap: 16px;
        border-radius: 16px;
        border: 1px solid #1E344F;
        color: #FEFEFE;
        background: #162639;

        h3 {
            font-family: "Plus Jakarta Sans";
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }

        p {
            font-size: 20px;
            line-height: 30px;
        }

        svg {
            height: 48px;
            min-height: 48px;
        }

        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        &:has(div){
            gap: 0;
        }

    }

}

@media( min-width: 1911px ){

    #alcool-lnd .list-squares .list-item {
        width: 434px;
        min-width: 434px;
    }

}

@media( min-width: 481px ) AND ( max-width: 1910px ){

    #alcool-lnd .list-squares .list-item {
        width: 440px;
        min-width: 440px;
    }

}

@media( max-width: 480px ){

    #alcool-lnd .list-squares {
        margin-top: 24px;
    }

}
