// Structure
@import 'structure/normalize';
@import 'structure/fonts';
@import 'structure/vars';
@import 'structure/animations';
@import 'structure/base';
@import 'structure/menu-toggle';
@import 'structure/header';
@import 'structure/footer';

// Components
@import 'components/swiper';
@import 'components/slider';
@import 'components/video-campanha';
@import 'components/loader-mask';
@import 'components/hero';
@import 'components/list-squares';
@import 'components/head-squares';
@import 'components/impactos';
@import 'components/outros-efeitos';
@import 'components/compartilhe';
@import 'components/banner-share';
@import 'components/newsletter';
@import 'components/brands';
@import 'components/modal-share';

// Internet explorer
//@import 'structure/ie';

@media( min-width: 733px ){
    body .just-mobile {
        display: none !important;
    }
}
@media( max-width: 732px ){
    body .hide-mobile {
        display: none !important;
    }
}