#alcool-lnd section.banner-share {

	padding: 50px 56px 72px;
	width: auto;
	max-width: initial;
	color: #FEFEFE;
	
	h2 {
		color: inherit;
		font-family: "Plus Jakarta Sans";
		font-size: 38px;
		font-style: normal;
		font-weight: 700;
		line-height: 43px;
		letter-spacing: initial;
	}

	form {
	
		width: 100%;
		max-width: 402px;
		min-width: 330px;

		fieldset {
			display: inline-flex;
			flex-direction: column;
			border: 0;
			padding: 0;
		}

		button.styled {

			margin-top: 16px;

			&,
			span {
				width: 100%;
				justify-content: center;
			}
			
		}
	
	}

	.item-radio {

		label {

			display: flex;
			align-items: center;
			gap: 16px;
			padding: 16px;
			border-radius: 28px;
			transition: background .3s ease-in-out;
			cursor: pointer;

			em {
				color: #FFE749;
				font-family: "Plus Jakarta Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				transition: color .3s ease-in-out;
			}

			i {

				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				border: 3px solid #FFE749;
				border-radius: 51%;
				transition: border .3s ease-in-out;

				&:after {
					content: '';
					display: block;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background: #FFE749;
					opacity: 0;
					transition: background .3s ease-in-out;
				}
			
			}

		}

		input {

			display: none;

			&:checked + label {

				cursor: default;

				i:after {
					opacity: 1;
				}
			
			}

		}
	
	}

	> div {
		background: #010101 no-repeat center center;
		background-size: cover;
		border-radius: 16px;
		overflow: hidden;
	}

	.quizz {

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 32px;
		height: 348px;
		padding: 0 56px;
		background-image: url('../images/bg-quizz.png');
		transition: opacity .3s ease-in-out;

		h2 {
			max-width: 540px;
		}

		&.hide {
			position: absolute;
			opacity: 0;
		}
	
	}

	.result {

		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 458px;
		padding-right: 4.7945205479%;
		border-radius: 16px;
		background-image: url('../images/bg-banner-share-sim.jpg');
		//opacity: 0;

		em {
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: 34px;
		}

		div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			position: relative;
			z-index: 10;
			width: 100%;
			max-width: 39.767699115%;
		}

		.graph {

			top: 0;
			left: 84px;
			width: 470px;
		
			span {

				padding-top: 97.6595744681%;

				&:before {
					background: url('../images/banner-share-firula-sim.png') no-repeat center center;
					background-size: cover;
				}
			
			}
		
		}

		&.style {

			&--2 {

				background-image: url('../images/bg-banner-share-nao.jpg');

				.graph span:before {
					background-image: url('../images/banner-share-firula-nao.png');
				}
			
			}

			&--3 {

				background-image: url('../images/bg-banner-share-votou.jpg');

				.graph span:before {
					background-image: url('../images/banner-share-firula-votou.png');
				}
			
			}

		}

		&.show {
			position: relative !important;
			transition: opacity .4s ease-in-out;
			transform: translateX(0) !important;
			opacity: 1 !important;
		}	

	}

}

@media( min-width: 1281px ){

	#alcool-lnd section.banner-share {

		.quizz {
			background-image: url('../images/bg-quizz-full.png');
		}

		.result {

			padding-right: 8.6283185841%;
			background-image: url('../images/bg-banner-share-sim-full.jpg');

			&.style {

				&--2 {
					background-image: url('../images/bg-banner-share-nao-full.jpg');
				}

				&--3 {
					background-image: url('../images/bg-banner-share-votou-full.jpg');
				}

			}

		}
	
	}

}

@media( min-width: 769px ){
	
	#alcool-lnd section.banner-share .item-radio {

		label {

			&:hover {

				background: #E3E4E8;

				em {
					color: #162639;
				}

				i {

					border-color: #162639;

					&:after {
						background-color: #162639;
					}
				
				}
			
			}

		}

		input:checked + label {

			&:hover {

				background: transparent;

				em {
					color: #FFE749;
				}

				i {

					border-color: #FFE749;

					&:after {
						background-color: #FFE749;
					}
				
				}
			
			}

			i:after {
				opacity: 1;
			}
			
		}
	
	}

}

@media( max-width: 1120px ){

	#alcool-lnd section.banner-share {
		
		h2 {
			font-size: 24px;
			line-height: 34px;
			font-weight: 400;
		}

		.item-radio label em {
			font-size: 16px;
			line-height: 32px;
		}

		.result {

			div {
				gap: 16px;
			}
		
			em {
				font-size: 16px;
				line-height: 24px; 
			}
		
		}

	}

}

@media( max-width: 1060px ){

	#alcool-lnd section.banner-share {

		.result {

			height: 368px;

			> div {
	  			max-width: 44%;
			}

		}
		
	}

}

@media( max-width: 1023px ){
	
	#alcool-lnd section.banner-share {

		.result {

			height: 368px;
			padding-left: 24px;
			padding-right: 24px;

			.graph {
				top: 0;
				left: calc( -3% - 1vw );
				width: 377px;
			}

		}
	
	}
	
}

@media (max-width: 710px) {
  	#alcool-lnd section.banner-share .result > div {
    	max-width: 56%;
  	}
}

@media( max-width: 767px ){

	#alcool-lnd section.banner-share {

		padding: 32px 16px;

		.quizz {
			gap: 24px;
			padding: 0 24px;
		}

		.item-radio label {
			padding: 13px 4px;
		}

		form {

  			width: 278px;
  			min-width: 278px;
  			max-width: 278px;

  			button.styled {
  				margin-top: 8px;
			}
		
		}
	
	}

}

@media( max-width: 640px ){

	#alcool-lnd section.banner-share {

		.quizz,
		.result {
			width: calc( 100dvw - 32px );
			height: 469px;
		}
	
		.result {

			align-items: flex-end;
			padding-bottom: 32px;
			background-image: url('../images/bg-banner-share-sim-mobile-big.jpg');

			> div {
				max-width: initial;
			}

			.graph {

				display: none;
				
				span:before {
					background-image: none;
				}
			
			}
		
			&.style {

				&--2 {
					background-image: url('../images/bg-banner-share-nao-mobile-big.jpg');
				}

				&--3 {
					background-image: url('../images/bg-banner-share-votou-mobile-big.jpg');
				}

			}

		}

		.quizz {

			flex-direction: column;
			background-image: url('../images/bg-quizz-mobile-big.jpg');

			h2 {
				max-width: 340px;
			}
		
		}
	
	}

}

@media (min-width: 481px ) AND ( max-width: 640px ){

	#alcool-lnd section.banner-share .result:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 20%;
		left: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 57%,rgba(1,1,1,1) 100%);
	}

}

@media( max-width: 480px ){

  	#alcool-lnd section.banner-share {
  	
  		.quizz {
    		background-image: url("../images/bg-quizz-mobile.jpg");
    	}

    	.result {

    		background-image: url('../images/bg-banner-share-sim-mobile.jpg');
    		background-position: center top;

    		button.styled,
    		a.button {
    			
    			&,
    			span {
    				width: 100%;
    				justify-content: center;
    			}

    			span {
    				height: 48px;
					font-size: 18px;
    			}
    		
    		}
		
			&.style {

				&--2 {
					background-image: url('../images/bg-banner-share-nao-mobile.jpg');
				}

				&--3 {
					background-image: url('../images/bg-banner-share-votou-mobile.jpg');
				}

			}
    	
    	}
  	
  	}

}