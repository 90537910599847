.piki-loader-full {

	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100dvw;
	height: 100dvh;
	background: rgba( 30, 52, 79, .8 );
  	//backdrop-filter: blur(7.5px);

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 64px;
		height: 64px;
		border-radius: 8px;
		background: #FFE749;
	}

	&.loaded span { 
		display: none; 
	}

}
