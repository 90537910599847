#alcool-lnd section.newsletter {

	padding: 50px 56px 44px;
	width: auto;
	max-width: initial;
	text-align: center;
	color: #FEFEFE;

	> div {

		padding: 62px 56px;
		border-radius: 16px;
		background: #010101 url('../images/bg-newsletter.png');
		background-size: cover;
		overflow: hidden;

		> div {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			transition: all .6s ease-in-out;
		}
	
	}

	.signup {
		max-width: 1080px;
		margin: 0 auto;	
		z-index: 5;
	}

	.message {

		position: absolute;
		top: 0;
		left: 0%;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0 56px;
		opacity: 0;
		transform: translateX(50%);
		z-index: 1;
		text-align: center;

		em {
			display: block;
			margin-top: 24px;
			text-align: center;
			font-family: 'Plus Jakarta Sans';
			font-size: 54px;
			font-style: normal;
			font-weight: 700;
			line-height: 54px;
			letter-spacing: -1.08px;
		}

		p {
			margin: 24px 0 0;
			font-size: 24px;
			line-height: 34px;
		}
	
	}

	header {

		h2 {
			font-family: 'Plus Jakarta Sans';
			font-size: 54px;
			font-weight: 700;
			line-height: 54px;
			letter-spacing: -1.08px;
			color: #FEFEFE;
		}
		em {
			display: block;
			margin-top: 14px;
			font-size: 24px;
			font-style: normal;
			line-height: 34px;
		}
		p {
			margin-top: 24px;
			font-size: 20px;
			line-height: 30px;
		}

	}
	
	form {

		width: 100%;
		margin: 32px 0 0;

		::placeholder {
			color: #B3B7C3;
			opacity: 1;
		}

		::-ms-input-placeholder {
		  	color: #B3B7C3;
		}
		
	}

	.progress {

		display: flex;
		align-items: center;
		gap: 12px;
		width: 100%;
		margin-top: 32px;

		strong {
			color: #FEFEFE;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		span {

			display: block;
			height: 8px;
			border-radius: 4px;

			span {
				width: 90%;
				background: #FFE749;
			}
		
		}

		> span {
			flex: 1;
			background: #E3E4E8;
		}
	
	}

	.field {

		text-align: left;

		.error-message {
			display: block;
			overflow: hidden;
			padding-top: 0;
			font-size: 18px;
			font-weight: 400;
			line-height: 27px;
			opacity: 0;
			transition: all .4s ease-in-out;
		}

		&.email {

			> div {
				position: relative;
			}

			input {

				width: 100%;
				height: 80px;
				padding: 0 254px 0 64px;
				border-radius: 5px;
				border: 1px solid #E3E4E8;
				background: #FEFEFE;
				outline: none !important;
				transition: all .4s ease-in-out;

				&:focus {
					border-color: #0012FF;
				}
				
			}

			button,
			.icon-mail {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
			.icon-mail {
				left: 16px;
			}
			button {
				right: 16px;
			}

			&.error {

				input {
					border: 2px solid #B20D30;
				}

				.icon-mail span {

					opacity: 0;
					
					&:last-child {
						opacity: 1;
					}
				
				}
			
			}

		}

		&.accept {

			margin-top: 24px;

			> div {

				label {
					display: flex;
					align-items: center;
					gap: 16px;
					cursor: pointer;
				}
				em {
					margin: 0;
					font-size: 16px;
					font-style: normal;
					line-height: 24px;
					font-weight: 300;
					text-align: left;
				}
				i {

					position: relative;
					display: block;
					width: 20px;
					min-width: 20px;
					height: 20px;
					border-radius: 6px;
					border: 2px solid #B3B7C3;
					background: #FFF;
					transition: all .4s ease-in-out;

					&:after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url('../svg/checkbox.svg') no-repeat center center;
						opacity: 0;
						transition: all .4s ease-in-out;
					}
				
				}
				input {

					display: none;

					&:checked + label i {
						
						border-color: #162639;

						&:after {
							opacity: 1;
						}

					}
					
				}

			}

			&.error > div i {
				border: 2px solid #B20D30;
			}
			
		}

		&.error .error-message {
			opacity: 1;
			padding-top: 8px;
		}

	}

	.icon-mail {
				
		position: relative;
		display: block;
		width: 28px;
		min-width: 28px;
		height: 22px;

		span {

			transition: all .4s ease-in-out;

			&:nth-child(1) {

				content: '';
				display: block;
				width: 100%;
				height: 100%;
				overflow: hidden;
				border-radius: 4px;

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: calc( 100% + 3px );
					margin-top: -3px;
					border: 3px solid #182e45;
					border-radius: 4px;
					border-top: 0;
				}

			}

			&:nth-child(2),
			&:nth-child(3) {
				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 0;
					display: block;
					width: 55%;
					height: 3px;
					background: #182e45;
					border-radius: 4px;
				}
			}

			&:nth-child(2){
				&:before,
				&:after {
					top: 8px;
					width: 63%;
				}
				&:before {
					left: -1px;
					transform: rotate(35deg);
				}
				&:after {
					right: -1px;
					transform: rotate(-35deg);
				}
			}

			&:nth-child(3){
				&:before,
				&:after {
					top: 0;
					width: 52%;
					transition: all .4s ease-in-out;
				}
				&:before {
					left: 2px;
				}
				&:after {
					right: 2px;
				}
			}

			&:nth-child(4){
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				background: url('../svg/field-erorr.svg') no-repeat center center;
				opacity: 0;
			}

		}
	
	}

	form.active .icon-mail span:nth-child(3){
		&:before,
		&:after {
			top: -4px;
			width: 56%;
		}
		&:before {
			left: 0;
			transform: rotate(-35deg);
		}
		&:after {
			right: 0;
			transform: rotate(35deg);
		}
	}

	&.success {

		.signup {
			transform: translateX(-50%);
			opacity: 0;
		}

		.message {
			transform: translateX(0);
			opacity: 1;
			z-index: 10;
		}

	}

}

@media( min-width: 1281px ){
	#alcool-lnd section.newsletter > div {
		background-image: url('../images/bg-newsletter-full.png');
	}
}

@media( max-width: 728px ){
	
	#alcool-lnd section.newsletter {

		header {
			
			h2 {
				font-size: 30px;
				line-height: 40px;
			}

			em {
				
				font-size: 20px;
				line-height: 30px;

				br {
					display: none;
				}
			
			}

			p {
				font-size: 16px;
				line-height: 24px;
			}

		}

		.field.email {

			> div > div {
				position: relative;
				display: block;
				width: 100%;
			}
		
			input {
				height: 64px;
				padding-right: 24px;
			}

			button {

				position: relative;
				top: initial;
				right: initial;
				margin-top: 24px;
				transform: none;

				&,
				span {
					width: 100%;
					justify-content: center;
				}
			
			}
		
		}
	
	}

}

@media( min-width: 561px ){

	#alcool-lnd section.newsletter h2 br {
		display: none;
	}

}

@media( max-width: 720px ){
	#alcool-lnd section.newsletter > div {
		background-image: url('../images/bg-newsletter-mobile-big.jpeg');
	}
}

@media( max-width: 560px ){

	#alcool-lnd section.newsletter {

		padding: 40px 16px;

		> div {
			padding: 32px 24px; 
		}

		header {
			em,
			p {
				text-align: left;
			}
		}

		.field.email input {
			padding-left: 60px;
			font-size: 16px;
		}

		.progress {
			margin-top: 24px;
		}
	
	}

}

@media( max-width: 480px ){

	#alcool-lnd section.newsletter {

		> div {
			background-image: url('../images/bg-newsletter-mobile.jpeg');
		}

		.message {

			padding: 0 24px;

			em {
				font-size: 30px;
				line-height: 40px;
				letter-spacing: 0;
			}
			
			p {
				font-size: 20px;
				line-height: 30px;
			}

			svg {
				width: 112px;
				height: auto;
			}
		
		}
	
	}

}
