#alcool-lnd section.impactos {

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 72px;

	h2 {
  		margin-left: -8px;
  		line-height: 57px;
	}

	ul {
		margin-top: 32px;
	}

	.graph {

		top: 50%;
		right: 0;

		span {
			transform: translateY(-50%);
		}

		&:nth-child(1){

			width: 100%;

			span {

				padding-top: 57.2481572482%;

				&:before {
					background-image: url('../images/impactos-fundo.png');
				}

			}

		}

		&:nth-child(2){

			width: 62.03931203931%;

			span {

				padding-top: 121.5841584158%;

				&:before {
					background-image: url('../images/impactos-copo.png');
				}

			}

		}
	
	}

}

@media( min-width: 921px ){

	#alcool-lnd section.impactos {

		> div {

			&:nth-child(1) {

				position: relative;
				flex: 1;

				div {
					position: absolute;
					right: 0;
					top: 50%;
					width: 814px;
					transform: translateY(-50%);
				}
			
			}

			&:nth-child(2) {
				width: 51.02739726027%;
			}
		
		}

	}

}

@media( max-width: 1122px ){

	#alcool-lnd section.impactos > div:nth-child(1) div {
		width: 600px;
	}

}

@media( min-width: 769px ) AND ( max-width: 1122px ){
	
	#alcool-lnd section.impactos h2 {
		font-size: 36px;
	  	line-height: 52px;
	}

}

@media( max-width: 920px ){

	#alcool-lnd section.impactos {

		flex-direction: column;

		.swiper-controls {
			margin-top: 2px;
		}

		> div {

			width: 100%;

			&:nth-child(1) div {

				position: relative;
	    		width: 100%;
	    		max-width: 480px;
    			margin: 0 auto;

	    		.graph:nth-child(1){

	    			top: initial;
					right: initial;
	    			position: relative;

	    			span {
	    				transform: none;
	    			}
	    		
	    		}

	    	}
  		
  		}
	
	}

}

@media( max-width: 768px ){

	#alcool-lnd section.impactos h2 {
		font-size: 30px;
		line-height: 40px;
	}

}

@media( max-width: 480px ){

	#alcool-lnd section.impactos {

		gap: 40px;
		padding-top: 0;

		h2 {
			margin-left: 0;
		}

		.graph {

			&:nth-child(1){

				width: 100dvw;
				margin: 0 -16px;

				span {

					padding-top: 77.2222222222%;

					&:before {
						background-image: url('../images/impactos-mobile-big.png')
					}
				
				}

			}

			&:nth-child(2){
				display: none;
			}

		}
		
	}

}


