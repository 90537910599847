#alcool-lnd section.hero {
	
	display: flex;
	justify-content: center;
	max-width: 100dvw;
	height: 100dvh;
	padding: 83px 0 24px;
	background: #162639 url('../images/bg-hero.jpg') no-repeat center center;
	background-size: cover;
	color: #FEFEFE;

	> div {
		
		@extend .center;
		flex: 1;
		display: flex;
		flex-direction: column;

		> div {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

	}

	.slider-wrapper {

		display: block;
		width: 100%;
		position: relative;
		z-index: 1;

		.swiper-slide {

			opacity: 0;
			transition: opacity .4s ease-in-out;

			&-active {
				opacity: 1;
			}
		
		}
	
	}

	h2,
	p,
	button {
		position: relative;
		z-index: 10;
	}


	h2 a {
		color: #FEFEFE;
		font-family: 'Plus Jakarta Sans';
		font-size: 70px;
		font-weight: 700;
		line-height: 70px;
		letter-spacing: -1.4px;
		text-decoration: none;
	}

	p {

		width: 550px;
		margin: 30px 0 0 -8px;

		strong,
		span {
			padding-left: 7px;
		}
	
	}

	cite {

		display: block;
		margin-top: 3px;
		color: #FEFEFE;
		font-size: 16px;
		line-height: 24px;
		font-style: normal;
		font-weight: normal;

		a {
			color: inherit;
		}
		
	}

	em {
		display: block;
		margin-top: 18px;
		font-size: 24px;
		line-height: 34px;
		font-weight: 300;
	}

	button.styled {
		margin-top: 24px;
	}

	button[data-action="scroll-down"] {

		display: inline-flex;
		flex-direction: column;
		align-items: center;
		align-self: center;
		gap: 16px;
		margin-top: 20px;
		border: 0;
		text-align: center;
		cursor: pointer;
		background: none;

		strong {
			color: #FFF;
			font-size: 20px;
			line-height: 30px;
			font-weight: normal;
		}

		.arrow {
			
			position: relative;
		    width: 34px;
		    height: 20px;

			&:before {
		    	content: '';
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    	width:34px;
		    	height:20px;
		    	background: url('../svg/arrow-go-down.svg') no-repeat center center;
		    	animation: jumpInfinite 2.4s infinite;
		    }
		    
		}

	}

}

@media( min-width: 1281px ){
	#alcool-lnd section.hero {
		background-image: url('../images/bg-hero-full.jpg');
	}
}

@media( max-width: 768px ){
	
	#alcool-lnd section.hero {
		
		h2 {
			font-size: 38px;
			line-height: 38px;
		}

		mark {
			font-size: 24px;
			line-height: 32px;
		}

		em {
			font-size: 18px;
			line-height: 27px;
		}

		p {
			width: initial;
			max-width: 440px;
		}

		> div > div {
  			max-width: 440px;
		}

	}

}

@media( max-width: 520px ){
	#alcool-lnd section.hero > div {
		padding-left: 16px;
	    padding-right: 16px;
	}
}

@media( min-width: 481px ){
	#alcool-lnd section.hero br.mobile {
		display: none;
	}
}

@media( max-width: 480px ){
	
	#alcool-lnd section.hero {

		background-image: url("../images/hero-mobile-big.jpg");
		background-position: top center;
		text-align: center;

		> div > div {
			align-items: center;
			justify-content: flex-end;
			max-width: 100dvw;
		}

		h2 {
		
			text-align: center;

			a {
				letter-spacing: initial;
				font-weight: 700;
				font-size: 44px;
				line-height: 42px
			}

			br {
				display: inline;
			}
		
		}

		mark {
			font-weight: 400;
			line-height: 33px;
		}

		p {

			max-width: initial;
			margin: 16px 0 0 0;
			
			br:not(.mobile):not(.any) {
				display: none;
			}
		
		}

		button.styled {
			display: none;
		}

		button[data-action="scroll-down"] strong {
			font-size: 16px;
			line-height: 24px;
		}
	
	}
	
}

@media( max-width: 360px ){
	#alcool-lnd section.hero {
		background-image: url("../images/hero-mobile.jpg");
	}
}
