#alcool-lnd section.compartilhe {

	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	gap: 72px; 

	h2 {
		margin-bottom: 12px;
		line-height: 54px;
	}

	p {

		color: #162639;
		font-size: 32px;
		line-height: 42px;

		&:not(:first-of-type){
			margin-top: 44px;
		}
	
	}

	.networks,
	a.button {
		margin-top: 48px;

	}

	> div {

		&:nth-child(1) {

			position: relative;
			flex: 1;

			div {
				position: absolute;
				left: 0;
				top: 50%;
				width: 814px;
				transform: translateY(-50%);
			}
		
		}

		&:nth-child(2) {
			width: 51.02739726027%;
		}
	
	}

	.graph {

		top: 50%;

		span {
			transform: translateY(-50%);
		}

		&:nth-child(1){

			width: 100%;
			right: 0;

			span {

				padding-top: 57.2481572482%;

				&:before {
					background-image: url('../images/share-fundo.png');
				}

			}

		}

		&:nth-child(2){

			width: 58.4766584767%;

			span {

				padding-top: 150%;

				&:before {
					background-image: url('../images/share-phone.png');
				}

			}

		}
	
	}

}

@media( max-width: 1080px ){

	#alcool-lnd section.compartilhe {

		flex-direction: column;

		.graph:nth-child(1){

			position: relative;
			top: initial;

			span {
				transform: none;
			}

		}

		 > div {

		 	&:nth-child(1),
		 	&:nth-child(2){
			 	width: 100%;
				max-width: 600px;
			}

			&:nth-child(1) {
			 	
			 	div {
					position: relative;
					left: initial;
					top: initial;
					width: 100%;
					margin: 22px auto 40px;
					transform: initial;
				}

			}
		
		}
	
	}

}

@media( max-width: 768px ){

	#alcool-lnd section.compartilhe {

		h2 {
			line-height: 40px;
		}

		p {

			font-size: 20px;
			line-height: 30px;

			&:not(:first-of-type) {
	  			margin-top: 30px;
			}

		}
	
	}

}

@media( max-width: 480px ){

	#alcool-lnd section.compartilhe {

		gap: 40px;
		padding-top: 0;

		.networks,
		a.button {
			margin-top: 24px;
		}

		.networks {
			width: 100%;
			justify-content: space-between;
		}

		a.button {
			&,
			span {
				width: 100%;
				justify-content: center;
			}
		}

		> div:nth-child(1) div {
			margin: 0;
		}
		
		.graph {

			&:nth-child(1){

				width: 100dvw;
				margin: 0 -16px;

				span {

					padding-top: 87.7777777778%;

					&:before {
						background-image: url('../images/share-fundo-mobile-big.png')
					}
				
				}

			}

			&:nth-child(2){
				display: none;
			}

		}
			
	}

}

@media (max-width: 360px) {
  	#alcool-lnd section.compartilhe .graph:nth-child(1) span::before {
    	background-image: url("../images/share-fundo-mobile.png");
  	}
}
