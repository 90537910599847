#alcool-lnd section.brands {

	padding-top: 0;
	padding-bottom: 0;

	h2 {
		margin-bottom: 16px;
	}

	p {

		font-size: 20px;
		line-height: 30px;
		color: #162639;

		&:not(:first-of-type){
			margin-top: 36px;
		}
	
	}

	.image-wrapper {

		position: relative;
		display: flex;
		width: 41.0102739726%;

		picture {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 1;
			padding: 0 15.4929577465%;
			border-radius: 16px;
			background: #162639;
			z-index: 10;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 5.03018108652%;
			bottom: 11.06639839034%;
			width: 50%;
			border-radius: 16px;
			background: #FFE749;
			z-index: 1;
		}
	
	}

	a.button,
	.networks {
		margin-top: 32px;
	}
	
	> div {

		display: flex;
		gap: 6.4212328767%;
		padding: 72px 0;

		> div {
			flex: 1;
		}

		&:nth-child(2) .image-wrapper picture {
			background: #E67108;		
		}
	
	}
	
}

@media( min-width: 1081px ){

	#alcool-lnd section.brands {

		> div {

			&:nth-child(1) {

				flex-direction: row-reverse;

				.image-wrapper:before {
					right: -21px;
				}
			
			}

			&:nth-child(2) .image-wrapper:before {
				left: -21px;
			}

		}

	}

}

@media( max-width: 1080px ){

	#alcool-lnd section.brands {

		.image-wrapper {

			width: 100%;
			max-width: 380px;
		
			picture {
				padding-top: 55px;
				padding-bottom: 55px;
			}

			&:before {
				top: 60px;
				right: -14px;
				bottom: -14px;
				left: -14px;
				width: auto;
			}
		
		}

		> div {
			flex-direction: column;
			gap: 32px;
		}
		
	}

}

@media (max-width: 480px) {

  	#alcool-lnd section.brands {

  		padding-right: 24px;
  		padding-left: 24px;

  		> div {
  			padding: 32px 0;
  		}

  		p {

	  		font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 27px;

			&:not(:first-of-type) {
  				margin-top: 27px;
			}
  		
  		}

  		a.button {

  			margin-top: 16px;

  			&,
  			span {
	  			width: 100%;
  				justify-content: center;
  			}
  		
  		}

  		.networks {

  			display: flex;
  			justify-content: space-between;
  			margin-top: 24px;

  			a {

				width: 48px;
				height: 48px;

				&:after {
					background-size: 24px auto;
				}
				&.facebook:after {
					background-size: 29px auto;
				}
				&.youtube:after {
					background-size: 24px auto;
				}
  			
  			}
  
  		}
  	
  	}

}
