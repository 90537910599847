#alcool-lnd section.head-squares {

	background: #F9F9FC;
	
	header {

		text-align: center;
		padding: 0 56px;

		em {
			display: block;
			margin-top: 16px;
			color: #1E344F;
			font-size: 32px;
			font-style: normal;
			font-weight: 400;
			line-height: 42px;
		}
	
	}

	.slider-wrapper {
		display: block;
		width: 100%;
		padding: 0 56px;
	}

}

@media( max-width: 1080px ){

	#alcool-lnd section.head-squares .slider-wrapper {
		padding: 0 16px;
	}
	
}

@media( max-width: 768px ){

	#alcool-lnd section.head-squares header {

		padding: 0 16px;
		
		em {
			font-size: 20px;
			line-height: 30px;
		}
	
	}

}

@media( max-width: 480px ){

	#alcool-lnd section.head-squares header {
  		text-align: left;
	}
	
}
