*,
*:before,
*:after {
    box-sizing: border-box;
}

// Prevent scroll top bug
html.locked-scroll.init-scroll {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
}

html,
body {
    background: #FFF !important;
    scroll-behavior: smooth;
}

#alcool-lnd {

    position: relative;
    width: 100%;
    overflow: hidden;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    z-index: 99;
    color: #333333;

    *, 
    *:before, 
    *:after {
        box-sizing: border-box;
    }

    .center {
        width: 100%;
        max-width: 1280px;
        padding-left: 56px;
        padding-right: 56px;
        margin: 0 auto;
    }

    h1, h2, h3, h4, h5, h6, em {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 100%;
    }

    h2 {

        color: #162639;
        font-family: 'Plus Jakarta Sans';
        font-size: 54px;
        font-weight: 700;
        line-height: 69px;
        letter-spacing: -1.08px;

        mark {
            font-size: inherit;
        }
    
    }

    img {
        max-width: 100%;
        height: auto;
    }

    mark {

        padding-left: 8px;
        padding-right: 8px;
        font-style: normal;
        color: #162639;
        font-family: 'Plus Jakarta Sans';
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        background-color: transparent;
        background-size: 200% 40px;
        background-position: 0 -15px;
        background-image: linear-gradient(90deg, rgba(255, 231, 73, 0) 50%, rgb(255, 231, 73) 0);
        transition: 
            background-position 1.6s,
            color 2s
        ;

        span {
            display: block;
            white-space: nowrap;
        }

        &.no-strong strong {
            font-weight: 700 !important;
        }

        &.animated {
            background-position: -100% -15px;
        }

    }

    .swiper {
    
        mark.animated {
            background-position: 0 -15px;
        }

        .swiper-slide-active mark {
            background-position: -100% -15px;
        }
    
    }

    a.button,
    button.styled {

        display: table;
        padding: 0;
        border: 0;
        background: transparent;
        text-decoration: none;

        &:not(:disabled){
            cursor: pointer;
        }

        span {

            display: inline-flex;
            align-items: center;
            gap: 12px;
            height: 56px;
            padding: 0 32px;
            color: #162639;
            font-family: 'Plus Jakarta Sans';
            font-size: 20px;
            font-weight: 600;
            border-radius: 5px;
            white-space: nowrap;
            background-color: #FFE749;
            transition: all .3s ease-in-out;
        
        }

        svg {
            filter: brightness(0) invert(10%) sepia(7%) saturate(5967%) hue-rotate(175deg) brightness(94%) contrast(89%);
            transition: all .3s ease-in-out;
        }

        &:not(:disabled):hover span {
            background-color: #FEFEFE;
        }

        &.arrow-right,
        &.download {
            span {
                padding-right: 24px;
            }
        }
        &.arrow-right {

            span:after {
                content: '';
                display: block;
                width: 24px;
                height: 24px;
                background: url( '../svg/arrow-button.svg' );
                transition: all .4s ease-in-out;
            }

            &:hover:not(:disabled) span:after {
                transform: translate(5px);
            }

        }

        &.color {

            &--2 {

                span {
                    background-color: #006280;
                }

                &:hover span {
                    background-color: darken( #006280, 8% );
                }
            
            }

            &--3 {

                span {
                    background-color: #FFEC00;
                    color: #333333;
                }

                &:hover span {
                    background-color: #B8A700;
                }
            
            }

        }

        &.hover {

            &--2:not(:disabled):hover span {

                background-color: #1E344F;
                color: #FFF;

                svg,
                &:after {
                    filter: brightness(0) invert(1);
                }
                
            }

        }

        &:disabled {
            opacity: 0.3;
        }

        &.medium {

            span {

                height: 48px;
                font-size: 18px;
                font-weight: 700;
                padding-bottom: 2px;

                &:after,
                svg {
                    transform: translateY(2px);
                }

            }

            &:hover:not(:disabled) span {
                &:after,
                svg {
                    transform: translate(5px,2px);
                }
            }
        
        }

        &.small span {
            height: 32px;
            font-size: 14px;
            font-weight: 500;
        }

        &.inline {

            span {

                height: 56px;
                padding: 0;
                color: #FEFEFE;
                font-family: 'Plus Jakarta Sans';
                font-size: 18px;
                font-weight: 600;
                background: none;

                &:after {
                    background-image: url('../svg/arrow-inline-button.svg');
                    filter: brightness(0) invert(1);
                    transform: translateY(2px);
                    transition: all .3s ease-in-out;
                }

            }

            &:hover:not(:disabled) span {

                background: transparent;
                
                &:after {
                    transform: translate(5px,2px);
                }
            
            }

        }
        
    }

    p {
        margin: 0;
    }

    em {

        font-style: normal;

        &.pink {
            color: #E5007E;
            font-weight: 500;
        }
    
    }

    cite {
        display: block;
        font-family: 'Inter';
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        line-height: 16px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    i {

        display: block;
        width: 30px;
        height: 30px;
        background: no-repeat center center;
        background-size: contain;

        &.coracao {
            background-image: url('../images/icon-coracao.svg');
        }
    
    }

    section {
        
        position: relative;
        padding-top: 72px;
        padding-bottom: 72px;

        &:not(.full){
            @extend .center;
        }
    
    }

    .video-container {

        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        max-width: 1168px;
        height: 0;
        
        iframe,
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            overflow: hidden;
            background-color: #162639;
        }
        iframe {
            z-index: 2;
        }
        div {
            object-fit: cover;
            z-index: 1;
        }

    }

    .list-style--1 {
        
        margin-top: 32px;

        &:not(.swiper){
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
        }

        h3 {
            color: #162639;
            font-size: 24px;
            font-weight: 400;
            line-height: 34px;
        }

        em {
            font-size: 24px;
            font-style: normal;
            line-height: 34px;
        }

        cite {

            font-style: normal;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;

            a {
                color: inherit;
                text-decoration-line: underline;
            }

        }

        .list-item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-left: 16px;
            border-left: 4px solid #FFE749;
            color: #162639;
        }

    }

    .networks {

        display: inline-flex;
        gap: 16px;

        a {

            position: relative;
            width: 56px;
            height: 56px;
            border-radius: 51%;
            overflow: hidden;
            text-indent: -999vw;
            background: #E3E4E8;
            transition: all .4s ease-in-out;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: no-repeat center center;
                transition: all .4s ease-in-out;
            }

            &.facebook:after {
                background-image: url('../svg/facebook.svg');
            }
            &.instagram:after {
                background-image: url('../svg/instagram.svg');
            }
            &.twitter:after {
                background-image: url('../svg/twitter.svg');
            }
            &.whatsapp:after {
                background-image: url('../svg/whatsapp.svg');
            }
            &.tiktok:after {
                background-image: url('../svg/tiktok.svg');
            }
            &.youtube:after {
                background-image: url('../svg/youtube.svg');
            }
            &.email:after {
                background-image: url('../svg/e-mail.svg');
            }

            &:hover {

                background-color: #162639;

                &:after {
                    filter: brightness(0) invert(1);
                }

            }
        
        }
    
    }
    
    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important;
    }
    
}

.graph {

    position: absolute;
    display: block;
    
    span {

        display: block;
        position: relative;
        width: 100%;
        height: 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: no-repeat center center;
            background-size: contain;
        }            
    
    }

}

@media( max-width: 980px ){
    
    #alcool-lnd div.sides {

        &,
        &.img-right {

            align-items: flex-start;
            flex-direction: column;
        
        }
    
    }

}

@media( max-width: 768px ){

    #alcool-lnd {

        h2 {
            font-size: 30px;
            line-height: 40px;
        }

        .center {
            padding-left: 36px;
            padding-right: 36px;
        }

    }

}

@media( max-width: 480px ){

    #alcool-lnd {

        #biblioteca h2 {
            font-size: 38px;
        }

        .list-style--1 {

            margin-top: 24px;
        
            .list-item {
                padding-left: 0;
                border-left: 0;
            }
        
        }

        section {

            padding-top: 40px;
            padding-bottom: 40px;

            &:has(.swiper){
                padding-bottom: 18px;
            }
        
        }

        .center {
            padding-left: 16px;
            padding-right: 16px;
        }

    }

}

@keyframes progress {
    from { right: 100%; }
    to { right: 0; }
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clear:before,
.clear:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden; 
}
.clear:after{ 
    clear: both;  
}
.clear { 
    zoom: 1; 
}



