#alcool-lnd section.outros-efeitos {

	display: block;
	width: 100%;
	background: #F9F9FC;
	
	h2 {
		text-align: center;
		color: #162639;
		font-family: 'Inter';
		font-size: 20px;
		font-weight: 400;
		line-height: 30px;
		text-transform: uppercase;
	}

	h3,
	mark {
		color: #162639;
		font-family: 'Plus Jakarta Sans';
		font-size: 72px;
		line-height: 94px;
		text-align: center;
	}

	h3 {
		font-weight: 200;
	}

	mark {
		font-weight: 800;
	}

	cite {

		color: #162639;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px;
		text-transform: uppercase;

		a {
			text-decoration: underline;
			color: inherit;
		}
	
	}

	.list-item > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 17px;
		padding: 0 56px;
		margin: 0 auto;
	}

	.swiper-controls {
		padding: 0 56px;
		margin-left: auto;
		margin-right: auto;
	}

	.list-dados {

		margin-top: 17px;

		&:not(.swiper) {

			display: flex;
			flex-direction: column;
			gap: 56px;
		
		}
	
	}

}

@media( max-width: 1660px ){
	
	#alcool-lnd section.outros-efeitos {

		h3, 
		mark {
			font-size: 50px;
			line-height: 76px;
		}

		.list-item > div,
		.swiper-controls {
			max-width: 1280px;
		}
	
	}

}

@media( max-width: 768px ){
	
	#alcool-lnd section.outros-efeitos {

		h2 {
			padding-right: 16px;
			padding-left: 16px;
			font-size: 30px;
			font-weight: 700;
			line-height: 40px;
			text-align: left;
			text-transform: initial;
		}

		h3, 
		mark {
			font-size: 24px;
			line-height: 34px;
		}

		h3 {
			text-align: left;
		}

		cite {
			font-size: 16px;
			line-height: 24px;
			text-transform: none;
		}

		.list-item > div {
			align-items: flex-start;
			gap: 24px;
			padding: 0 16px;
		}

		.swiper-controls {
			margin-top: 2px;
		}
	
	}

}
